<template>
    <div class="order-content">
        <el-table :data="orderList" class="customTable goods-text-table" style="width: 100%; flex: 1; margin-top: 20px" height="100%">
            <el-table-column label="产品名称" align="left" width="380px">
                <template slot-scope="scope">
                    <router-link class="answer" :to="{path: '/goods/detail', query: {g_id: scope.row.id }}" target="_blank">
                        <img :src="scope.row.goods_img" alt="">
                        <span class="text">{{scope.row.goods_title}}</span>
                    </router-link>
                </template>
            </el-table-column>
            <el-table-column prop="order_no" label="订单编号" align="center"></el-table-column>
            <el-table-column label="单价/数量" align="center">
                <template slot-scope="scope">
                    <p>￥{{scope.row.unit_price}}</p>
                    <p>   x {{scope.row.number}}</p>
                </template>
            </el-table-column>
            <el-table-column  label="物流信息" align="center">
                <template slot-scope="scope">
                    <p>{{scope.row.logistics>0?scope.row.logistics:'暂无快递信息'}}</p>
                    <p>{{scope.row.odd_numbers>0?scope.row.odd_numbers:'暂无物流单号'}}</p>
                </template>
            </el-table-column>
            <el-table-column prop="pay_amount" label="支付金额" align="center"></el-table-column>
            <el-table-column label="订单状态" align="center">
                <template slot-scope="scope">
                    <span>{{scope.row.order_status}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="scoring_operation" label="操作" align="center">
                <template slot-scope="scope">
                    <el-link type="success"  :underline="false" @click="orderBtn(scope.row)">{{scope.row.is_answer===1?'修改':scope.row.scoring_operation}}</el-link>
                </template>
            </el-table-column>
        </el-table>
        <!-- 弹窗-->
        <el-dialog :close-on-click-modal="false" :close-on-press-escape="false" :title="deliveredOrderTitle" @close="resetForm()"
                   custom-class="green-dialog02" :visible.sync="deliveredOrderDialog" width="500px">
            <el-form :model="deliveredOrderForm" ref="deliveredOrderForm" label-position="right" label-width="100px">
                <el-form-item label="订单号:"  prop="order_no">
                    <div class="order-no-title">{{deliveredOrderForm.order_no}}</div>
                </el-form-item>
                <div class="order-item">
                    <el-form-item prop="logistics">
                        <el-select v-model="deliveredOrderForm.logistics" placeholder="请选择" style="width:180px">
                            <el-option
                                    v-for="item in logisticsList"
                                    :key="item"
                                    :label="item"
                                    :value="item">
                            </el-option>
                        </el-select>
                        <el-input-number v-model="deliveredOrderForm.odd_numbers" :precision="0" :controls="false" placeholder="请输入物流单号" style="width:256px"></el-input-number>
                    </el-form-item>
                </div>
            </el-form>
            <div class="dialog-footer">
                <el-button @click="deliveredOrderDialog = false">取 消</el-button>
                <el-button style="margin-left: 30px" type="primary" @click="addOrderBtn()">出库</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {stuOpOrderList,stuOpOrderOperation,stuOpModifyOrder } from '@/utils/apis.js'
    export default {
        name: "DeliverGoods",
        data(){
            return{
                orderList:[],
                deliveredOrderForm:{
                    logistics:'',
                    order_no:'',
                    odd_numbers:void 0,
                    delivery_status:'',
                    reasons_rejection:''
                },
                logisticsList:[],
                deliveredOrderTitle:'',
                deliveredOrderDialog:false,
            }
        },
        mounted() {
            this.getStuOpOrderList()
        },
        methods:{
            // 获取订单列表
            getStuOpOrderList(){
                let param = {
                    type:2
                }
                if (this.$route.query.id) {
                    param.op_id = this.$route.query.id;
                }
                if (this.$route.query.course_id) {
                    param.course_id = this.$route.query.course_id;
                }
                stuOpOrderList(param).then((res) => {
                    this.orderList = res.data.list
                }).catch((err) => {
                    console.log(err);
                })
            },
            // 获取订单操作
            getStuOpOrderOperation() {
                let param = {
                    id: this.orderId
                }
                if (this.$route.query.id) {
                    param.op_id = this.$route.query.id;
                }
                if (this.$route.query.course_id) {
                    param.course_id = this.$route.query.course_id;
                }
                stuOpOrderOperation(param).then((res) => {
                    this.logisticsList = res.data.logistics
                }).catch((err) => {
                    console.log(err);
                })
            },
            orderBtn(row) {
                this.deliveredOrderDialog = true;
                this.orderId = row.id
                this.deliveredOrderForm.order_no = row.order_no
                this.deliveredOrderForm.logistics = row.logistics
                this.deliveredOrderForm.odd_numbers = row.odd_numbers == '0' ? '': row.odd_numbers
                this.deliveredOrderTitle = '修改快递单号'
                this.getStuOpOrderOperation()
            },
            // 订单确定事件
            addOrderBtn() {
                let param = {
                    id:this.orderId,
                    logistics:this.deliveredOrderForm.logistics,
                    logistics_number:this.deliveredOrderForm.odd_numbers
                }
                if (this.$route.query.id) {
                    param.op_id = this.$route.query.id;
                }
                if (this.$route.query.course_id) {
                    param.course_id = this.$route.query.course_id;
                }
                stuOpModifyOrder(param).then((res) => {
                    this.$message.success(res.msg);
                    this.getStuOpOrderList()
                    this.deliveredOrderDialog = false;
                }).catch((err) => {
                    console.log(err);
                })
            },
            // 重置表单
            resetForm(){
                this.$refs.deliveredOrderForm.resetFields();
                this.deliveredOrderForm = this.$options.data().deliveredOrderForm;
            },
        }
    }
</script>

<style scoped lang="scss">
    .order-content{
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: 0 20px;
        .answer{
            display: flex;
            align-items: center;
            img{
                width: 80px;
                height: 80px;
            }
            .text{
                padding-left: 12px;
            }
        }
        .order-item{
            display: flex;
            align-items: center;
            ::v-deep .el-form-item__content{
                display: flex;
                margin-left: 24px!important;
            }
            ::v-deep .el-input-number {
                .el-input__inner {
                    text-align: left;
                }
            }
        }
        .dialog-footer{
            margin-top: 20px;
            text-align: center;
        }
    }

</style>